import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'

import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Headline3 } from '@/shared/components/Typography'
import { IPost } from '@/shared/models/Post'
import { WebsitePageType } from '@/shared/models/Website'
import EmptyPlaceholder from 'components/Sections/Placeholders/EmptyPlaceholder'
import SectionCardMedia from 'components/Sections/SectionMedia/SectionCardMedia'
import { sectionMessages } from 'components/Sections/messages'
import { ISectionComponentProps, getPostUrl } from 'components/Sections/utils'
import { useTranslations } from 'contexts/translations'
import { useSystemPagePath } from 'utils/hooks'
import { getPostMedia } from 'services/posts'

function PostThreeLatestSection(props: ISectionComponentProps): JSX.Element {
    const {
        data: { posts },
        section: { name, content },
    } = props
    const intl = useIntl()
    const postsLink = useSystemPagePath(WebsitePageType.POSTS)
    const recentPosts: IPost[] = posts?.slice(0, 3)
    const categoriesQuery = content?.categories?.length ?
        content.categories.map(c => c.id).join(',') : ''

    if (!posts.length) {
        return (
            <EmptyPlaceholder
                mods='media-placeholder'
                title={intl.formatMessage(sectionMessages['posts.empty_posts'])}
                fullScreenSize={3}
                smallScreenSize={1}
            />
        )
    }

    return (
        <Box pb={4}>
            <Box pb={4}>
                <Headline3>{name}</Headline3>
            </Box>
            <Grid container spacing={4}>
                {recentPosts.map((element) => (
                    <Grid key={element.id} item md={4} sm={12} xs={12}>
                        <CardComponent post={element} />
                    </Grid>
                ))}
            </Grid>
            <Box pb={4} pt={4} justifyContent='center' display='flex'>
                <SecondaryButton applyBackgroundColor href={postsLink + (categoriesQuery ? `?categories=${categoriesQuery}` : '')}>
                    <FormattedMessage {...sectionMessages['website.view_more']} />
                </SecondaryButton>
            </Box>
        </Box>
    )
}

function CardComponent(props: { post: IPost }): JSX.Element {
    const { post } = props
    const { timePassedFormatter } = useTranslations()
    const [media, setMedia] = useState<string>('')

    useEffect(() => {
        getMedia()
    }, [])

    async function getMedia() {
        const media = await getPostMedia(post)
        setMedia(media)
    }

    return (
        <SectionCardMedia
            media={media}
            link={getPostUrl(post)}
            title={post.title}
            mods='icon-center'
            target='_top'
            description={post.publication_date ? timePassedFormatter(post.publication_date) : ''}
        />
    )
}

export default PostThreeLatestSection
