import { FormattedMessage } from 'react-intl'
import Box from '@material-ui/core/Box'

import Card from '@/shared/components/Card'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Headline4 } from '@/shared/components/Typography'
import { WebsitePageType } from '@/shared/models/Website'
import { sectionMessages } from 'components/Sections/messages'
import { useSystemPagePath } from 'utils/hooks'
import { IPostPlayerProfileProps } from '../types'
import { usePostPlayerProfileStyles } from '../styles'

const emptyRosterIcon = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/websites/empty-roster-mobile.svg`

type PlayerStatisticInfoType = {
    competitionName: string
    seasonName: string
    jerseyNumber: number
    stats: {
        name: string
        value: string
    }[]
}

function PostPlayerProfile(props: IPostPlayerProfileProps): JSX.Element {
    const { data, content } = props
    const classes = usePostPlayerProfileStyles()
    const playerBaseLink = useSystemPagePath(WebsitePageType.PLAYER)

    function getStatistics(): PlayerStatisticInfoType {
        const contentGroupId = content?.content?.group?.id
        const contentSeasonId = content?.content?.season?.id
        const contentCompetitionId = content?.content?.competition?.id

        const stats = data?.allStats?.filter((stat) => {
            if (contentSeasonId) {
                return stat.group?.id === contentGroupId && stat.season?.id === contentSeasonId
            }
            return stat.group?.id === contentGroupId
        })

        const rows = stats?.length
            ? stats[0].rows?.filter((row) => {
                  if (contentCompetitionId) return row.competition?.id === contentCompetitionId
                  return true
              })
            : []

        const row = rows?.length ? rows[0] : null
        const sportStat = data?.sportsStatsById && row ? data?.sportsStatsById[row.group?.sport?.id] : null

        const queryPosition = data?.playerPositions?.find((position) => {
            if (contentSeasonId) {
                return position.group?.id === contentGroupId && position.season?.id === contentSeasonId
            }
            return position.group?.id === contentGroupId
        })

        const jerseyNumber = typeof queryPosition?.jerseyNumber === 'number' ? queryPosition.jerseyNumber : null

        if (sportStat) {
            const statsData = Object.keys(sportStat)
                .map((key) => {
                    if (key.startsWith('stat')) return key
                })
                .filter(Boolean)
                .map((key) => {
                    return {
                        name: data?.messages[sportStat[key]?.name] || sportStat[key]?.translate_name,
                        value: row[key],
                    }
                })

            if (statsData?.length)
                return {
                    competitionName: row.competition?.name,
                    seasonName: row.season?.name,
                    jerseyNumber,
                    stats: statsData,
                }
        }
        return {
            competitionName: '',
            seasonName: '',
            jerseyNumber: null,
            stats: [],
        }
    }

    return (
        <Box pb={4}>
            <Box className={classes.playerProfile}>
                <Box className={classes.pictureWrapper}>
                    <div className={classes.pictureContainer}>
                        <img
                            src={data?.player?.pic_avatar || emptyRosterIcon}
                            alt='profile picture'
                            className={classes.picture}
                        />
                        {typeof getStatistics().jerseyNumber === 'number' ? (
                            <div className={classes.playerNumberWrapper}>
                                <span className={classes.playerNumber}>
                                    <b>#{getStatistics().jerseyNumber}</b>
                                </span>
                            </div>
                        ) : null}
                    </div>
                </Box>
                <Box className={classes.playerInfo}>
                    <Headline4>
                        {data?.player?.firstname || ''} {data?.player?.lastname || ''}
                    </Headline4>
                    <h5 className={classes.subHeading}>
                        {getStatistics().competitionName} {getStatistics().seasonName}
                    </h5>
                    <Box className={classes.statisticsList}>
                        {getStatistics().stats.map((stat, index) => {
                            return (
                                <Box key={`${stat.value}-${index}`} className={classes.statisticsItem}>
                                    <Card>
                                        <div className={classes.statistics}>
                                            <p className={classes.statisticsText}>{stat.name}</p>
                                            <p className={classes.statisticsText}>
                                                <b>{stat.value}</b>
                                            </p>
                                        </div>
                                    </Card>
                                </Box>
                            )
                        })}
                    </Box>
                    <SecondaryButton
                        applyBackgroundColor
                        className={classes.viewProfileButton}
                        href={`${playerBaseLink}/players/${data?.player?.id}`}
                    >
                        <span className={classes.viewProfile}>
                            <FormattedMessage {...sectionMessages['posts.view_profile']} />
                        </span>
                    </SecondaryButton>
                </Box>
            </Box>
            <Box className={classes.viewProfileMobileButtonWrapper}>
                <SecondaryButton
                    applyBackgroundColor
                    className={classes.viewProfileMobileButton}
                    href={`${playerBaseLink}/players/${data?.player?.id}`}
                >
                    <span className={classes.viewProfile}>
                        <FormattedMessage {...sectionMessages['posts.view_profile']} />
                    </span>
                </SecondaryButton>
            </Box>
        </Box>
    )
}

export default PostPlayerProfile
