import { ISectionComponentProps } from '../utils'
import PostPage from './Layouts/PostPage'

function PostSection(props: ISectionComponentProps): JSX.Element {
    const {
        section: { name, content },
        data: { posts },
    } = props

    return <PostPage title={name} posts={posts} categories={content?.categories || []} section={props.section} />
}

export default PostSection
