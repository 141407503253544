import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Slug } from '@/shared/components/Slug'
import { Headline3, Paragraph2 } from '@/shared/components/Typography'
import { IPost } from '@/shared/models/Post'
import { WebsitePageType } from '@/shared/models/Website'
import EmptyPlaceholder from 'components/Sections/Placeholders/EmptyPlaceholder'
import SectionCardMedia from 'components/Sections/SectionMedia/SectionCardMedia'
import SectionLongCard from 'components/Sections/SectionMedia/SectionLongCard'
import { sectionMessages } from 'components/Sections/messages'
import { ISectionComponentProps, getPostUrl } from 'components/Sections/utils'
import { useTranslations } from 'contexts/translations'
import { useSystemPagePath } from 'utils/hooks'
import { getPostMedia } from 'services/posts'

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`

function PostSpotlightSection(props: ISectionComponentProps): JSX.Element {
    const {
        data: { posts },
        section: { name, content },
    } = props
    const intl = useIntl()
    const postsLink = useSystemPagePath(WebsitePageType.POSTS)
    const first: IPost = posts[0]
    const recentPosts: IPost[] = posts?.slice(1, 5)
    const categoriesQuery = content?.categories?.length ?
            content.categories.map(c => c.id).join(',') : ''

    if (!posts.length) {
        return (
            <EmptyPlaceholder
                mods='media-placeholder'
                title={intl.formatMessage(sectionMessages['posts.empty_posts'])}
                fullScreenSize={3}
                smallScreenSize={1}
            />
        )
    }

    return (
        <Box pb={4}>
            <Box pb={4}>
                <Headline3>{name}</Headline3>
            </Box>
            <Grid container spacing={4}>
                <Grid item lg={6} md={12} xs={12}>
                    <CardComponent post={first} />
                </Grid>
                <Grid container direction='column' item lg={6} md={12} spacing={4}>
                    {recentPosts.map((element) => (
                        <Box key={element.id} py={2} px={2}>
                            <CardLongComponent post={element} />
                        </Box>
                    ))}
                </Grid>
            </Grid>
            <Box pb={4} pt={4} justifyContent='center' display='flex'>
                <SecondaryButton applyBackgroundColor href={postsLink + (categoriesQuery ? `?categories=${categoriesQuery}` : '')}>
                    <FormattedMessage {...sectionMessages['website.view_more']} />
                </SecondaryButton>
            </Box>
        </Box>
    )
}

function CardComponent(props: { post: IPost }): JSX.Element {
    const { post } = props
    const { timePassedFormatter } = useTranslations()
    const [media, setMedia] = useState<string>('')

    useEffect(() => {
        getMedia()
    }, [])

    async function getMedia() {
        const media = await getPostMedia(post)
        setMedia(media)
    }

    const title = post?.title?.length > 100 ? `${post.title.slice(0, 100)}...` : post?.title

    return (
        <SectionCardMedia
            media={media}
            mods='icon-right'
            icon={
                post?.publication_date ? (
                    <Slug
                        imgSrc={`${iconPath}/websites/Time.svg`}
                        text={
                            <Paragraph2 isAccent>
                                <b>{timePassedFormatter(post?.publication_date)}</b>
                            </Paragraph2>
                        }
                    />
                ) : null
            }
            title={title}
            description={''}
            link={getPostUrl(post)}
            mediaMods='featured'
            isArticle
            bigTitle
            useNextImage={true}
        />
    )
}

function CardLongComponent(props: { post: IPost }): JSX.Element {
    const { post } = props
    const { timePassedFormatter } = useTranslations()
    const [media, setMedia] = useState<string>('')

    useEffect(() => {
        getMedia()
    }, [])

    async function getMedia() {
        const media = await getPostMedia(post)
        setMedia(media)
    }

    return (
        <SectionLongCard
            slug={
                post.publication_date ? (
                    <Slug
                        imgSrc={`${iconPath}/websites/Time.svg`}
                        text={
                            <Paragraph2 isAccent>
                                <b>{timePassedFormatter(post.publication_date)}</b>
                            </Paragraph2>
                        }
                    />
                ) : null
            }
            link={getPostUrl(post)}
            title={post.title}
            media={media}
            useNextImage={true}
        />
    )
}

export default PostSpotlightSection
