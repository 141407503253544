import moment from 'moment'

export const DAY_MONTH_YEAR_FORMAT = 'Do MMMM YYYY'
export const DATE_EU_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_EU_FORMAT = 'DD/MM/YYYY HH:mm'
export const TIME_EU_FORMAT = 'HH:mm:ss'
export const TIME_NO_SEC_EU_FORMAT = 'HH:mm'
export const DATE_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
export const INVALID_DATE = 'INVALID_DATE'
export const INVALID_MIN_DATE = 'INVALID_MIN_DATE'

/**
 * To use with redux form to normalize date into ISO format
 */
export const isoDateNormalizer = (value, nullIfInvalid =  false) => {
    const currentDate = moment(value, DATE_EU_FORMAT, true)

    if (!currentDate.isValid()) {
        return nullIfInvalid? null : value
    }

    return currentDate.format()
}

export const dateIsFar = date => {
    return moment().month() !== 11 && moment(date) > moment().add(30, 'days')
}