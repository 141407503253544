import Box from '@material-ui/core/Box'
import parse from 'html-react-parser'
import moment from 'moment'

import { Headline2, Paragraph1 } from '@/shared/components/Typography'
import { DAY_MONTH_YEAR_FORMAT } from '@/shared/utils/formDateUtils'
import { AdManager } from '@/shared/components/AdManager/AdManager'
import { PostContentType } from '@/shared/models/Post'
import { useClub } from 'contexts/club'
import { IPostSingleSectionProps } from '../types'
import { usePostStyles } from '../styles'
import PostParagraph from './PostParagraph'
import PostVideo from './PostVideo'
import PostPlayerProfile from './PostPlayerProfile'
import PostImages from './PostImages'
import PostGame from './PostGame'
import PostEvent from './PostEvent'
import MultipleDocuments from 'components/Sections/Documents/Layout/MultipleDocuments'
import PostButton from './PostButton'
import PostLink from './PostLink'
import PostForm from './PostForm'

export default function PostSingleSection(props: IPostSingleSectionProps): JSX.Element {
    const { post, contentsFetchedInfo, section } = props
    const classes = usePostStyles()
    const { club, modes } = useClub()

    function ContentElement({ content }: { content: PostContentType }): JSX.Element {
        switch (content.layout) {
            case 'paragraph':
                return <PostParagraph text={parse(content.content.paragraph)} />
            case 'video':
                return <PostVideo videoUrl={content.content.url} />
            case 'playerProfile':
                const data = contentsFetchedInfo.find((c) => c.contentId === content.id)
                return <PostPlayerProfile data={data.layout === 'playerProfile' ? data.data : null} content={content} />
            case 'images':
                return (
                    <PostImages
                        pictures={content.content.images.map((i) => i.url)}
                        photographer={content.content.images[0]?.photographer}
                    />
                )
            case 'game':
                const gameData = contentsFetchedInfo.find((c) => c.contentId === content.id)
                return <PostGame game={gameData.layout === 'game' ? gameData.game : null} />
            case 'event':
                const eventData = contentsFetchedInfo.find((c) => c.contentId === content.id)
                return <PostEvent event={eventData.layout === 'event' ? eventData.event : null} />
            case 'documents':
                const documents = content.content.documents?.map((d, index) => {
                    return {
                        id: index,
                        name: d.name,
                        url: d.url,
                        created: post.publication_date,
                    }
                })
                return (
                    <MultipleDocuments
                        /* eslint-disable @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                        section={{ content: { documents: documents }, name: '' }}
                    />
                )
            case 'button':
                return <PostButton link={content.content.url} title={content.content.text} />
            case 'link':
                return <PostLink link={content.content.url} title={content.content.title} />
            case 'form':
                const formData = contentsFetchedInfo.find((c) => c.contentId === content.id)
                return <PostForm form={formData.layout === 'form' ? formData.form : null} />
            default:
                return null
        }
    }

    function renderAdManager(blockId: number): JSX.Element {
        const adClub = club?.id ?? 0
        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='newsarticle'
                        blockId={blockId}
                        sizes={[
                            [300, 50],
                            [234, 60],
                        ]}
                        color={modes.find((m) => m.type === section?.mode.type)?.background}
                    />
                )}
            </div>
        )
    }

    return (
        <article className={classes.post}>
            {renderAdManager(club.id)}
            <Box pb={4}>
                <Headline2>{post.title}</Headline2>
                {post?.publication_date ? (
                    <Paragraph1 className={classes.postDate}>
                        {moment(post?.publication_date).format(DAY_MONTH_YEAR_FORMAT)}
                    </Paragraph1>
                ) : null}
                {post?.club ? (
                    <Box className={classes.clubName}>
                        {post?.club?.banner_big && (
                            <img
                                src={post?.club?.banner_big}
                                className={classes.clubLogo}
                                width={32}
                                height={32}
                                alt='club logo'
                            />
                        )}
                        <Paragraph1>{post?.club?.name}</Paragraph1>
                    </Box>
                ) : null}
            </Box>
            {post.contents
                .sort((a, b) => a.position - b.position)
                .map((content) => {
                    return <ContentElement key={content.id} content={content} />
                })}
        </article>
    )
}
