import Box from '@material-ui/core/Box'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import Card from '@/shared/components/Card'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Headline3, Headline4, Paragraph2 } from '@/shared/components/Typography'
import IconButton from '@/shared/components/Button/IconButton'
import { WebsitePageType } from '@/shared/models/Website'
import { useSystemPagePath } from 'utils/hooks'
import { DEFAULT_CLUB_LOGO } from '@/shared/components/Avatar/Avatar'
import { displayLiveStreamButton } from '@/shared/utils/helpers'
import { eventPageMessages } from 'components/Event/consts'
import { usePostGameStyles } from '../styles'
import { IPostGameProps } from '../types'

function PostGame(props: IPostGameProps): JSX.Element {
    const { game } = props
    const postGameClasses = usePostGameStyles()
    const gameBaseLink = useSystemPagePath(WebsitePageType.GAME)
    const showLiveStreamButton = displayLiveStreamButton(game)

    function goToGame(link: string): void {
        window.location.href = link
    }

    return (
        <Box pb={4} className={postGameClasses.gameWrapper}>
            <Card>
                <Box className={postGameClasses.game}>
                    <Box className={postGameClasses.gameInfoWrapper}>
                        <Box className={postGameClasses.gameInfo}>
                            <Box className={postGameClasses.gameTeam}>
                                <Box>
                                    <img
                                        className={postGameClasses.teamLogo}
                                        src={game?.team1?.pic || DEFAULT_CLUB_LOGO}
                                        width={24}
                                        height={24}
                                    />
                                </Box>
                                <Box>
                                    <Headline4 className={postGameClasses.teamName}>{game?.team1?.name}</Headline4>
                                </Box>
                            </Box>
                            <Box className={postGameClasses.gameScore}>
                                <Box>
                                    <Headline3 className={postGameClasses.score}>
                                        {typeof game?.score1 === 'number' ? game?.score1 : '-'} :{' '}
                                        {typeof game?.score2 === 'number' ? game?.score2 : '-'}
                                    </Headline3>
                                </Box>
                                <Box>
                                    <Paragraph2 className={postGameClasses.date}>
                                        {moment(game?.start_date).format('DD.MM.YYYY')}
                                    </Paragraph2>{' '}
                                </Box>
                            </Box>
                            <Box className={postGameClasses.gameTeam}>
                                <Box>
                                    <img
                                        className={postGameClasses.teamLogo}
                                        src={game?.team2?.pic || DEFAULT_CLUB_LOGO}
                                        width={24}
                                        height={24}
                                    />
                                </Box>
                                <Box>
                                    <Headline4 className={postGameClasses.teamName}>{game?.team2?.name}</Headline4>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={postGameClasses.leaveStreamMobile}>
                            {showLiveStreamButton && (
                                <SecondaryButton applyBackgroundColor href={game?.stream_link}>
                                    <FormattedMessage {...eventPageMessages['event.page.live_stream']} />
                                </SecondaryButton>
                            )}
                        </Box>
                    </Box>
                    <Box className={postGameClasses.actions}>
                        <Box className={postGameClasses.leaveStream}>
                            {showLiveStreamButton && (
                                <SecondaryButton applyBackgroundColor href={game?.stream_link}>
                                    <FormattedMessage {...eventPageMessages['event.page.live_stream']} />
                                </SecondaryButton>
                            )}
                        </Box>
                        <IconButton onClick={() => goToGame(`${gameBaseLink}/${game?.id}`)}>
                            <span className={postGameClasses.chevron}>&rsaquo;</span>
                        </IconButton>
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}

export default PostGame
