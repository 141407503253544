import Box from '@material-ui/core/Box'
import parse from 'html-react-parser'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import Card from '@/shared/components/Card'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Headline3, Paragraph1 } from '@/shared/components/Typography'
import { useSystemPagePath } from 'utils/hooks'
import { WebsitePageType } from '@/shared/models/Website'
import { sectionMessages } from 'components/Sections/messages'
import { IPostEventProps } from '../types'
import { usePostEventStyles, usePostStyles } from '../styles'

function PostEvent(props: IPostEventProps): JSX.Element {
    const { event } = props
    const classes = usePostEventStyles()
    const postClasses = usePostStyles()
    const systemEventLink = useSystemPagePath(
        event.type === 'game'
            ? WebsitePageType.GAME
            : event.type === 'training'
            ? WebsitePageType.TRAINING
            : WebsitePageType.EVENT
    )

    return (
        <Box className={classes.wrapper}>
            <Card>
                <Box className={classes.content}>
                    <Headline3>{event?.title}</Headline3>
                    <Paragraph1>{event?.start_date ? moment(event?.start_date).format('D MMMM YYYY') : ''}</Paragraph1>
                    {event?.information && <Paragraph1>{parse(event.information)}</Paragraph1>}
                    {event?.venue_address_name && <Paragraph1>{event.venue_address_name}</Paragraph1>}
                    <Box>
                        <SecondaryButton applyBackgroundColor href={`${systemEventLink}/${event?.id}`}>
                            <span className={postClasses.buttonText}>
                                <FormattedMessage {...sectionMessages['posts.view_event_page']} />
                            </span>
                        </SecondaryButton>
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}

export default PostEvent
