import { Box } from '@material-ui/core'
import { Paragraph1 } from '@/shared/components/Typography'
import { usePostStyles } from '../styles'
import { IPostLinkProps } from '../types'

function PostLink(props: IPostLinkProps): JSX.Element {
    const { link, title } = props
    const postClasses = usePostStyles()

    return (
        <Box className={postClasses.sectionWrapper}>
            <a href={link} target='_blank' rel='noreferrer'>
                <Paragraph1 className={postClasses.link}>
                    {title}
                </Paragraph1>
            </a>
        </Box>
    )
}

export default PostLink
