import { ITheme } from '@/shared/styles/MuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';


export const useSingleGalleryStyle = makeStyles((theme: ITheme) => ({
    masonry: {
        display: 'flex',
        width: '100%',
        flexFlow: 'row wrap',
        marginLeft: '-8px',
        counterReset: 'brick',
    },
    masonryGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    masonryItem: {
        overflow: 'hidden',
        margin: theme.spacing(0,0,2,2),
        position: 'relative',
        flex: 'auto',
        height: 250,
        minWidth: 150,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            height: 'unset'
        }
    },
    masonryImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        verticalAlign: 'middle',
        maxWidth: '100%'
    },
    singleImage: {
        width: '100%'
    },
    link: {
        textDecorationColor: theme.props.sectionMode.text_background
    },
    adRoot: {
        width: '100%',
        display: 'flex',
        margin: '0 0 24px',
        justifyContent: 'center'
    }
}))
