import { makeStyles } from '@material-ui/core'

export const useDocumentSectionStyles = makeStyles(theme => ({
    details: {
        marginTop: '4px'
    },
    downloadButton: {
        '& img': {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    }
}))
