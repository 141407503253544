type BrowserName = 'chrome' | 'safari' | 'firefox' | ''

export function detectBrowser(): BrowserName {
    if (typeof window !== 'undefined') {
        const userAgent = window.navigator.userAgent
        if (/safari/i.test(userAgent) && !/chrome|android/i.test(userAgent)) {
            return 'safari'
        } else if (/chrome/i.test(userAgent)) {
            return 'chrome'
        } else if (/firefox/i.test(userAgent)) {
            return 'firefox'
        } else {
            return ''
        }
    }
}