import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Hidden } from '@material-ui/core'
import { useRouter } from 'next/router'

import { Slug } from '@/shared/components/Slug'
import SectionCardMedia from 'components/Sections/SectionMedia/SectionCardMedia'
import InfiniteScroll from '@/shared/components/InfiniteScroll'
import { sectionMessages } from 'components/Sections/messages'
import { Headline2, Headline3, Paragraph2 } from '@/shared/components/Typography'
import SectionLongCard from 'components/Sections/SectionMedia/SectionLongCard'
import { useTranslations } from 'contexts/translations'
import { IPost } from '@/shared/models/Post'
import { getPosts } from 'services/posts'
import { useClub } from 'contexts/club'
import Loader from '@/shared/components/Loader/Loader'
import { AdManager } from '@/shared/components/AdManager/AdManager'
import { useInfiniteListStyles } from 'components/Sections/Calendar/styles'
import EmptyPlaceholder from 'components/Sections/Placeholders/EmptyPlaceholder'
import { getMediaOfPost, getPostUrl } from 'components/Sections/utils'
import { IPostPageProps } from '../types'
import { POSTS_PAGE_LIMIT } from '../consts'
import { usePostStyles } from '../styles'

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`

export default function PostPage(props: IPostPageProps): JSX.Element {
    const { posts: postsProp, title, categories, section } = props
    const first: IPost = postsProp[0]
    const recentPosts: IPost[] = postsProp?.slice(1, 5)
    const intl = useIntl()
    const classes = useInfiniteListStyles()
    const postClasses = usePostStyles()
    const { query } = useRouter()

    const { timePassedFormatter } = useTranslations()
    const { prefix, club, modes } = useClub()

    const [activePage, setActivePage] = useState(1)
    const [posts, setPosts] = useState<IPost[]>(postsProp || [])
    const [hasMore, setHasMore] = useState(posts.length >= POSTS_PAGE_LIMIT)

    async function fetchMore() {
        const categoriesParam = query?.categories || categories?.map((c) => c.id)?.join(',')
        const result = await getPosts({
            clubPrefix: prefix,
            query: {
                _limit: POSTS_PAGE_LIMIT,
                _offset: POSTS_PAGE_LIMIT * activePage,
                _categories: categoriesParam,
                _status: 'published',
            },
        }).catch(() => [])
        setActivePage((prev) => prev + 1)
        setPosts((prev) => prev.concat(result))
        if (result.length === 0) {
            setHasMore(false)
        }
    }

    function renderAdManager(blockId: number): JSX.Element {
        const adClub = club?.id ?? 0
        return (
            <div key={`${blockId}-ad`}>
                {adClub && (
                    <AdManager
                        adType='newsarticle'
                        blockId={blockId}
                        sizes={[
                            [300, 50],
                            [234, 60],
                        ]}
                        color={modes.find((m) => m.type === section?.mode.type)?.background}
                    />
                )}
            </div>
        )
    }

    if (!posts.length) {
        return (
            <EmptyPlaceholder
                mods='media-placeholder'
                title={intl.formatMessage(sectionMessages['posts.empty_posts'])}
                fullScreenSize={3}
                smallScreenSize={1}
            />
        )
    }

    return (
        <Box>
            <Hidden lgUp>
                <Box pb={4}>{renderAdManager(club.id)}</Box>
            </Hidden>
            <Box pb={4} className={postClasses.postPageTitle}>
                <Headline2>{title}</Headline2>
                <Hidden mdDown>{renderAdManager(club.id)}</Hidden>
            </Box>
            <Box pb={4}>
                <Grid container spacing={4}>
                    <Grid item lg={6} md={12} xs={12}>
                        <SectionCardMedia
                            media={getMediaOfPost(first)}
                            mods='icon-right'
                            icon={
                                first?.publication_date ? (
                                    <Slug
                                        imgSrc={`${iconPath}/websites/Time.svg`}
                                        text={
                                            <Paragraph2 isAccent>
                                                <b>{timePassedFormatter(first?.publication_date)}</b>
                                            </Paragraph2>
                                        }
                                    />
                                ) : null
                            }
                            title={first?.title}
                            description={''}
                            link={getPostUrl(first)}
                            mediaMods='featured'
                            isArticle
                            bigTitle
                            useNextImage={true}
                        />
                    </Grid>
                    <Grid container direction='column' item lg={6} md={12} spacing={4}>
                        {recentPosts.map((element) => (
                            <Box key={element.id} py={2} px={2}>
                                <SectionLongCard
                                    slug={
                                        element.publication_date ? (
                                            <Slug
                                                imgSrc={`${iconPath}/websites/Time.svg`}
                                                text={
                                                    <Paragraph2 isAccent>
                                                        <b>{timePassedFormatter(element.publication_date)}</b>
                                                    </Paragraph2>
                                                }
                                            />
                                        ) : null
                                    }
                                    link={getPostUrl(element)}
                                    title={element.title}
                                    media={getMediaOfPost(element)}
                                    useNextImage={true}
                                />
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Box>
            <Box pb={4} pt={4}>
                <Headline3>
                    <FormattedMessage {...sectionMessages['posts.all_posts']} />
                </Headline3>
            </Box>
            <InfiniteScroll
                dataLength={posts.length}
                next={fetchMore}
                className={classes.infiniteList}
                hasMore={hasMore}
                loader={<Loader />}
            >
                <Box pb={4}>
                    <Grid container spacing={4}>
                        {posts.map((element) => (
                            <Grid key={element.id} item md={4} sm={12} xs={12}>
                                <SectionCardMedia
                                    media={getMediaOfPost(element)}
                                    link={getPostUrl(element)}
                                    title={element.title}
                                    mods='icon-center'
                                    target='_top'
                                    description={
                                        element.publication_date ? timePassedFormatter(element.publication_date) : ''
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </InfiniteScroll>
        </Box>
    )
}
