import ReactPlayer from 'react-player/lazy'
import Box from '@material-ui/core/Box'
import { IPostVideoProps } from '../types'
import { usePostVideoStyles } from '../styles'

function PostVideo(props: IPostVideoProps): JSX.Element {
    const { videoUrl } = props
    const classes = usePostVideoStyles()
    return (
        <Box pb={4} className={classes.playerWrapper}>
            <ReactPlayer
                url={videoUrl}
                width='100%'
                height='100%'
                onError={() => null}
                controls
                onPlay={() => null}
            />
        </Box>
    )
}

export default PostVideo
