import { SectionLayoutType } from '@/shared/models/Section'
import { ISectionComponentProps } from '../utils'
import PostSingleSection from './Layouts/PostSingleSection'
import PostSpotlightSection from './Layouts/PostSpotlightSection'
import PostSection from './PostSection'
import PostThreeLatestSection from './Layouts/PostThreeLatestSection'
import PostImagePreviewSection from './Layouts/PostImagePreviewSection'
import PostSingleVideoSection from './Layouts/PostSingleVideoSection'
import FlfNewsSection from '../News/Layout/FlfNews'

function PostContainer(props: ISectionComponentProps): JSX.Element {
    const { data, section } = props
    const isSinglePost = Boolean(data.post)

    if (section.layout === SectionLayoutType.SINGLE && section.content.post) {
        return (
            <PostSingleVideoSection
                post={data.post}
                postSectionContent={section.content.post}
                title={section.name}
                section={section}
            />
        )
    }

    if (isSinglePost && section.layout === SectionLayoutType.POSTS_IMAGE_PREVIEW) {
        return <PostImagePreviewSection {...props} />
    }

    if (isSinglePost) {
        return <PostSingleSection post={data.post} contentsFetchedInfo={data.contentsFetchedInfo} section={section} />
    }

    if (section.layout === SectionLayoutType.POSTS_SPOTLIGHT) {
        return <PostSpotlightSection {...props} />
    }

    if (section.layout === SectionLayoutType.POSTS_THREE_LATES) {
        return <PostThreeLatestSection {...props} />
    }

    if (section.layout === SectionLayoutType.FLF) {
        return <FlfNewsSection {...props} />
    }

    return <PostSection {...props} />
}

export default PostContainer
