import { makeStyles } from '@material-ui/core'
import { Colors } from '@/shared/styles/MuiTheme'

export const usePostStyles = makeStyles((theme) => ({
    post: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    buttonText: {
        fontSize: 14,
    },
    sectionWrapper: {
        padding: '16px 0',
    },
    postDate: {
        padding: '10px 0',
    },
    clubName: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    clubLogo: {
        objectFit: 'contain'
    },
    adRoot: {
        flex: 1,
        width: '100%',
        display: 'flex',
    },
    postPageTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    link: {
        textDecoration: 'underline'
    },
    paragraph: {
        '& a': {
            color: 'inherit',
        },
    }
}))

export const usePostVideoStyles = makeStyles((theme) => ({
    playerWrapper: {
        height: '469px',
        [theme.breakpoints.down('lg')]: {
            height: '420px',
        },
        [theme.breakpoints.down('md')]: {
            height: '350px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '192px',
        },
    },
}))

export const usePostPlayerProfileStyles = makeStyles((theme) => ({
    playerProfile: {
        display: 'flex',
        gap: 16,
        width: '100%',
    },
    pictureWrapper: {
        position: 'relative',
    },
    playerNumberWrapper: {
        position: 'absolute',
        top: 12,
        width: 57,
        height: 54,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: Colors.FlfRedColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: 44,
            height: 42,
        },
    },
    playerNumber: {
        fontSize: 24,
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
    },
    pictureContainer: {
        position: 'relative',
        width: 266,
        height: 355,
        [theme.breakpoints.down('md')]: {
            width: 164,
            height: 218,
        },
    },
    picture: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        objectFit: 'contain',
        backgroundColor: '#ffffff',
    },
    subHeading: {
        fontSize: 12,
        margin: 0,
    },
    playerInfo: {
        width: '100%',
    },
    statisticsItem: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    statistics: {
        padding: '2px 12px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    statisticsText: {
        margin: 0,
    },
    statisticsList: {
        padding: '12px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        [theme.breakpoints.down('sm')]: {
            gap: 4,
        },
    },
    viewProfileButton: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    viewProfileMobileButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
        },
    },
    viewProfileMobileButton: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    viewProfile: {
        fontSize: 14,
    },
}))

export const usePostGameStyles = makeStyles((theme) => ({
    gameWrapper: {
        borderRadius: 8,
        overflow: 'hidden',
        padding: 0,
    },
    game: {
        display: 'flex',
        padding: '12px 16px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            gap: 16,
            padding: 12,
        },
    },
    gameInfoWrapper: {
        width: '100%',
    },
    gameInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: 69,
        [theme.breakpoints.down('md')]: {
            gap: 15,
        },
        [theme.breakpoints.down('sm')]: {
            gap: 5,
        },
    },
    gameTeam: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    gameScore: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            width: '100%',
        },
    },
    date: {
        textAlign: 'center',
    },
    score: {
        textAlign: 'center',
    },
    actions: {
        width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 12,
        [theme.breakpoints.down('md')]: {
            width: 'unset',
        },
    },
    teamName: {
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
    chevron: {
        fontSize: 24,
        position: 'relative',
        bottom: 2,
    },
    leaveStream: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    leaveStreamMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 12,
        },
    },
    teamLogo: {
        objectFit: 'contain',
    },
}))

export const usePostEventStyles = makeStyles((theme) => ({
    wrapper: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    content: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
}))

export const usePostFormStyles = makeStyles((theme) => ({
    formWrapper: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    form: {
        padding: 16,
    },
    title: {
        marginBottom: 8,
    },
    paragraph: {
        marginBottom: 12,
    },
}))

export const usePostImagestyles = makeStyles(() => ({
    imageWrapper: {
        position: 'relative',
    },
    cover: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.70)',
        color: '#ffffff',
        fontSize: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    photographer: {
        marginBottom: 10
    }
}))
