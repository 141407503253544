import { FormattedMessage } from 'react-intl'
import { Box } from '@material-ui/core'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Headline3 } from '@/shared/components/Typography'
import { useImageStyles } from 'components/Sections/Image/styles'
import EmptyPlaceholder from 'components/Sections/Placeholders/EmptyPlaceholder'
import { sectionMessages } from 'components/Sections/messages'
import { ISectionComponentProps, getPostUrl } from 'components/Sections/utils'

function PostImagePreviewSection(props: ISectionComponentProps): JSX.Element {
    const {
        data: { post },
        section,
    } = props
    const postsLink = getPostUrl(post)
    const classes = useImageStyles()

    function getPreviewImages(): string[] {
        const contentImages = post?.contents?.find((c) => c.layout === 'images')
        if (contentImages?.layout === 'images') {
            const images = contentImages.content?.images?.slice(0, section?.customize?.format?.amount_of_images || 2)
            if (images?.length) {
                return images.map((i) => i.url)
            }
        }

        return []
    }

    return (
        <Box>
            <Box pb={4}>
                <Headline3>{post?.title || ''}</Headline3>
            </Box>
            {getPreviewImages().length ? (
                <div className={classes.multipleImagesWrapper}>
                    {getPreviewImages().map((element) => (
                        <div key={element} className={classes.imageLandscape}>
                            <img src={element} className={`${classes.multipleImagesImage}`} alt='post picture' />
                        </div>
                    ))}
                </div>
            ) : (
                <EmptyPlaceholder mods='media-placeholder' title={null} fullScreenSize={3} smallScreenSize={1} />
            )}
            <Box pb={4} pt={4} justifyContent='center' display='flex'>
                <SecondaryButton applyBackgroundColor href={postsLink}>
                    <FormattedMessage {...sectionMessages['website.view_more']} />
                </SecondaryButton>
            </Box>
        </Box>
    )
}

export default PostImagePreviewSection
