import { ISectionComponentProps } from 'components/Sections/utils';
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Tag from '@/shared/components/Tag/Tag';
import { Paragraph2, Headline2 } from '@/shared/components/Typography';
import Button from '@/shared/components/Button/MButton'
import { Separator } from '@/shared/components/Separator/Separator';
import { useIntl } from 'react-intl';
import { sectionMessages } from 'components/Sections/messages';
import { downloadUrl } from '@/shared/utils/helpers';
import { IDocument } from '@/shared/models/Document';
import { useTranslations } from 'contexts/translations';
import { useDocumentSectionStyles } from '../styles';

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`

export default function MultipleDocuments(props: ISectionComponentProps) {
    const { section } = props
    const intl = useIntl()
    const classes = useDocumentSectionStyles()
    const { simpleDateFormatter } = useTranslations()

    return (
        <Box>
            <Box pb={4}>
                <Headline2>{section.name}</Headline2>
            </Box>
            {section?.content?.documents?.map<React.ReactNode>(document => (
                <Box key={document.id} pb={2} display='flex'>
                    <Box pr={4}>
                        <Tag size='large' imgSrc={`${iconPath}/website-builder/16px/Document.svg`} />
                    </Box>
                    <Box display='flex' flex={1} justifyContent='center' flexDirection='column'>
                        <Paragraph2>
                            <b>
                                {document.name}
                            </b>
                        </Paragraph2>
                        <Hidden mdUp>
                            <Paragraph2 className={classes.details}>
                                {`${intl.formatMessage(sectionMessages['document.published_on'])} ${simpleDateFormatter(document.created, 'DD.MM.YYYY')}`}
                            </Paragraph2>
                        </Hidden>
                    </Box>
                    <Hidden smDown>
                        <Box display='flex' flex={1} justifyContent='center' flexDirection='column'>
                            <Paragraph2>
                                {`${intl.formatMessage(sectionMessages['document.published_on'])} ${simpleDateFormatter(document.created, 'DD.MM.YYYY')}`}
                            </Paragraph2>
                        </Box>
                    </Hidden>
                    <Box display='flex' alignItems='center' justifyContent='flex-end' className={classes.downloadButton}>
                        <Button onClick={() => onDownload(document)} disableRipple isIcon color="inherit" imgSrc={`${iconPath}/websites/Download.svg`}/>
                    </Box>
                </Box>
            )).reduce((prev, curr, i) => [prev, <Separator pb={2} key={i}/>, curr])}
        </Box>
    )

    function onDownload(document: IDocument) {
        downloadUrl(document?.url, true)
    }
}