import { Paragraph1 } from '@/shared/components/Typography'
import Box from '@material-ui/core/Box'
import { IPostParagraphProps } from '../types'
import { usePostStyles } from '../styles'

function PostParagraph(props: IPostParagraphProps): JSX.Element {
    const { text } = props
    const postClasses = usePostStyles()

    return (
        <Box pb={4}>
            <Paragraph1 className={postClasses.paragraph}>{text}</Paragraph1>
        </Box>
    )
}

export default PostParagraph
