import { FormattedMessage } from 'react-intl'
import { Box, Card } from '@material-ui/core'
import { useRouter } from 'next/router'

import { Headline3, Paragraph1 } from '@/shared/components/Typography'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { StartPage } from '@/shared/models/Steps'
import { getClubUrl } from '@/shared/utils/getClubUrl'
import { useClub } from 'contexts/club'
import { sectionMessages } from 'components/Sections/messages'
import { usePostFormStyles, usePostStyles } from '../styles'
import { IPostFormProps } from '../types'

function PostForm(props: IPostFormProps): JSX.Element {
    const { form } = props
    const classes = usePostFormStyles()
    const postClasses = usePostStyles()
    const { club } = useClub()
    const router = useRouter()

    const path = router.asPath
    const formPath = `/steps/view/form_select_account/${form.id}?steps=view_form_select_account,view_form_personal_data,view_form_questions,view_form_payments&start_page=${StartPage.WEB_PAGE}&path=${path}`
    const formLink = `${getClubUrl(club)}${formPath}`

    return (
        <Box className={classes.formWrapper}>
            <Card>
                <Box className={classes.form}>
                    <Headline3 className={classes.title}>{form?.name}</Headline3>
                    <Paragraph1 className={classes.paragraph}>{form?.description}</Paragraph1>
                    <SecondaryButton applyBackgroundColor href={formLink}>
                        <span className={postClasses.buttonText}>
                            <FormattedMessage {...sectionMessages['posts.fill_out_form']} />
                        </span>
                    </SecondaryButton>
                </Box>
            </Card>
        </Box>
    )
}

export default PostForm
