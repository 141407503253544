import { Box } from '@material-ui/core'
import ButtonLink from '@/shared/components/Button/ButtonLink'
import { usePostStyles } from '../styles'
import { IPostButtonProps } from '../types'

function PostButton(props: IPostButtonProps): JSX.Element {
    const { link, title } = props
    const postClasses = usePostStyles()

    return (
        <Box className={postClasses.sectionWrapper}>
            <ButtonLink href={link} openInNewWindow={false}>
                {title}
            </ButtonLink>
        </Box>
    )
}

export default PostButton
