import Box from '@material-ui/core/Box'

import { Headline3 } from '@/shared/components/Typography'
import { AdManager } from '@/shared/components/AdManager/AdManager'
import { useClub } from 'contexts/club'
import { IPostSingleVideoSectionProps } from '../types'
import PostVideo from './PostVideo'
import { usePostStyles } from '../styles'

export default function PostSingleVideoSection(props: IPostSingleVideoSectionProps): JSX.Element {
    const { post, title, postSectionContent, section } = props
    const { club, modes } = useClub()
    const classes = usePostStyles()

    function getVideoUrl(): string {
        if (postSectionContent?.videoUrl) return postSectionContent.videoUrl
        else {
            const videoContent = post?.contents?.find((c) => c.layout === 'video')
            return videoContent.layout === 'video' ? videoContent.content.url : ''
        }
    }

    function renderAdManager(blockId: number): JSX.Element {
        const adClub = club?.id ?? 0
        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='videos'
                        blockId={blockId}
                        sizes={[
                            [300, 50],
                            [234, 60],
                        ]}
                        color={modes.find((m) => m.type === section?.mode.type)?.background}
                    />
                )}
            </div>
        )
    }

    return (
        <Box>
            <Box pb={4}>
                <Headline3>{title}</Headline3>
            </Box>
            <Box pb={4}>{renderAdManager(club.id)}</Box>
            <PostVideo videoUrl={getVideoUrl()} />
        </Box>
    )
}
