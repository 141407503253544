import { IPost } from '@/shared/models/Post'
import { request } from './api'
import { detectVideoPlatform, getMediaOfPost, getVimeoId, getYoutubePreview } from 'components/Sections/utils'

export async function getPosts({
    clubPrefix,
    query,
}: {
    clubPrefix: string
    query: Record<string, unknown>
}): Promise<IPost[]> {
    return request({
        endpoint: '/posts',
        method: 'GET',
        clubPrefix,
        query,
    })
}

export async function getPost({ postId, clubPrefix }: { postId: number; clubPrefix: string }): Promise<IPost> {
    return request({
        endpoint: `/posts/${postId}`,
        method: 'GET',
        clubPrefix,
    })
}

export async function getVimeoVideoData(
    id: string
): Promise<{ id: string; thumbnail_medium: string; thumbnail_small: string }[]> {
    const response = await fetch(`https://vimeo.com/api/v2/video/${id}.json`)
    return response.json()
}

export async function getPostMedia(post: IPost): Promise<string> {
    const imageMedia = getMediaOfPost(post)
    if (imageMedia) {
        return imageMedia
    }
    const videoContent = post?.contents?.find((c) => c.layout === 'video')
    if (videoContent?.layout === 'video' && detectVideoPlatform(videoContent?.content?.url) === 'youtube') {
        const youTubePreview = getYoutubePreview(videoContent?.content.url)
        return youTubePreview
    }
    if (videoContent?.layout === 'video' && detectVideoPlatform(videoContent?.content?.url) === 'vimeo') {
        const id = getVimeoId(videoContent?.content.url)
        const result = await getVimeoVideoData(id)
        if (Array.isArray(result)) {
            return result[0]?.thumbnail_medium
        }
    }
    return ''
}
