import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { FormattedMessage } from 'react-intl'

import { useSingleGalleryStyle } from 'components/Sections/SingleGallery/styles'
import { detectBrowser } from '@/shared/utils/detectBrowser'
import { Paragraph1, Paragraph2 } from '@/shared/components/Typography'
import { sectionMessages } from 'components/Sections/messages'
import { usePostImagestyles } from '../styles'

interface IProps {
    pictures: string[]
    photographer: string
}

const PostImages: React.FunctionComponent<IProps> = (props) => {
    const { pictures, photographer } = props
    const galleryClasses = useSingleGalleryStyle()
    const classes = usePostImagestyles()
    const browser = detectBrowser()
    const [lightBoxIndex, setLightBoxIndex] = useState(-1)
    const picturesToDisplay = pictures?.slice(0, 4)

    const isOpen = lightBoxIndex !== -1
    const isFirst = lightBoxIndex === 0
    const isLast = lightBoxIndex === pictures.length - 1
    const showCoverOfLastImage = pictures.length > 4

    function handleLightBoxAction(action: 'next' | 'prev' | 'close') {
        if (action === 'next' && !isLast) {
            setLightBoxIndex((prev) => prev + 1)
        } else if (action === 'prev' && !isFirst) {
            setLightBoxIndex((prev) => prev - 1)
        } else if (action === 'close') {
            setLightBoxIndex(-1)
        }
    }

    return (
        <Box>
            {photographer && (
                <Paragraph1 className={classes.photographer}>
                    <FormattedMessage {...sectionMessages['section.gallery.photographed_by']} /> {photographer}
                </Paragraph1>
            )}
            {picturesToDisplay.length > 1 && (
                <Box pb={4} className={browser === 'safari' ? galleryClasses.masonryGrid : galleryClasses.masonry}>
                    {isOpen && (
                        <Lightbox
                            nextSrc={!isLast && pictures[lightBoxIndex + 1]}
                            prevSrc={!isFirst && pictures[lightBoxIndex - 1]}
                            mainSrc={pictures[lightBoxIndex]}
                            onCloseRequest={() => handleLightBoxAction('close')}
                            onMovePrevRequest={() => handleLightBoxAction('prev')}
                            onMoveNextRequest={() => handleLightBoxAction('next')}
                        />
                    )}
                    {picturesToDisplay.map((e, index) => (
                        <div
                            onClick={() => setLightBoxIndex(index)}
                            key={e}
                            className={`${galleryClasses.masonryItem} ${classes.imageWrapper}`}
                        >
                            {showCoverOfLastImage && picturesToDisplay.length - 1 === index && (
                                <div className={classes.cover}>
                                    <b>+{pictures.length - 4}</b>
                                </div>
                            )}
                            <img src={e} className={galleryClasses.masonryImage} alt='image' />
                        </div>
                    ))}
                </Box>
            )}
            {picturesToDisplay.length === 1 && (
                <Box>
                    <img src={picturesToDisplay[0]} className={galleryClasses.singleImage} alt='image' />
                </Box>
            )}
        </Box>
    )
}

export default PostImages
